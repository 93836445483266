<template><v-app style="background-color: #fafafa">
    <showdialog :message="messages"></showdialog>
    <terms :message="messages"></terms>
    <showactionresult :message="messages"></showactionresult>
    <v-app-bar app
               clipped-left
               tile
               v-bind:height="isMobile ? 48 : 60"
               color="white"
               elevation="1">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="primary--text"></v-app-bar-nav-icon>
        <v-row class="">
            <v-col cols="auto" class="d-flex align-center ml-4">
                <router-link to="/Account/Login">
                    <img v-bind:height="20" src="@/assets/logo.png" alt="Logo" />
                </router-link>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="d-flex align-center">
                <span class="d-flex align-center font-weight-bold primary--text caption" style="cursor:pointer" @click="OpenHelpDocument()">
                    <v-icon small left>mdi-lifebuoy</v-icon> {{ $t("UserGuide") }}
                </span>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
                <span class="d-flex align-center font-weight-bold primary--text caption" style="cursor:pointer" @click="ChangeLanguage()">
                    <v-icon small left>mdi-earth</v-icon> {{ $t("ChangeLanguageText") }}
                </span>
            </v-col>
            <v-col cols="auto">
                <v-divider vertical></v-divider>
            </v-col>
            <v-col cols="auto">
                <span class="font-weight-medium title">{{ $t("SupplierPortal") }}</span>
            </v-col>
        </v-row>
    </v-app-bar>
    <v-navigation-drawer app clipped mobile-breakpoint="sm" disable-resize-watcher disable-route-watcher v-model="drawer" color="primary" width="280">
        <v-layout column align-center>
            <v-flex class="mt-5 text-center">
                <v-avatar size="64" class="primary--text white display-1 font-weight-bold mb-4">
                    {{GetUserGorevInitials}}
                </v-avatar>
                <p class="title my-0 white--text">
                    {{GetUserName}}
                </p>
                <p class="caption mb-0 white--text">
                    {{GetUserGorev}}
                </p>
            </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <leftmenu :form="this.form"
                  @menuclicked="LeftMenuClicked($event)">
        </leftmenu>

        <template v-slot:append>
            <div class="pa-2">
                <v-btn depressed
                       text
                       block
                       @click="Logout()"
                       color="red">
                    <span>{{ $t("AL_Logout") }}</span>
                    <v-icon right>exit_to_app</v-icon>
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
    <br />
    <router-view />
</v-app>
</template>
<script>
    import Vue from 'vue';
    import Vuex from 'vuex';

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';

    import moment from 'moment';
    import Utilities from '@/Utilities.js'

    import showDialog from '@/components/ShowDialog.vue';

    import { forEach } from 'core-js/fn/array';

    export default {
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            leftmenu,
            kview
        },
        props: {
            form: String,
            message: Object,
            single: { type: Boolean, default: false },
        },
        data: function () {
            return {
                drawer: true,
                valid: false,
                lazy: true,
                username: '',
                viewName: 'benim',
                //form: '',
                timeNow: '',
                fullPage: true,
                currentform: 'formlayout',
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' }
            };
        },
        methods: {
            SetWhatTheF: function (what)
            {
                this.$store.commit('SetWhatToShow', { show: what });
            },
            Logout() {
                var payload = {
                    action: 'Logout',
                    formdata: {
                        formInputs: {},
                    },
                };

                this.$store.dispatch("AccountAction", payload)
                    .then(response => {
                        this.$store.commit("SetUserLoggedIn", false);
                        var userInfo = {};

                        userInfo.AdSoyad = "";
                        userInfo.Role = "";
                        userInfo.Gorev = "";
                        userInfo.Login = 0;
                        userInfo.FirmaNo = "";
                        userInfo.UniqueId = "";

                        this.$store.commit("SetUserField", userInfo);
                        this.$router.push({ name: 'login' });
                    })

            },
            UpdateTime() {
                var timeNow = moment().format("DD/MM/YYYY HH:mm");
                this.timeNow = timeNow;
            },
            ChangeLanguage() {
                var lang = Utilities.GetCurrentLanguage();

                if (lang == 'tr') {
                    lang = 'en';
                }
                else {
                    lang = 'tr';
                }

                this.$root.loadLanguageAsync(lang);
            },
            TimeLoop() {
                setTimeout(() => {
                    this.TimeLoop();
                    Utilities.log("Keep Alive");
                    this.$store.dispatch("KeepAlive")
                }, 30000);
                this.UpdateTime();
            },
            ChangeNotification(payload) {
                this.validateError = payload.validateError;
            },
        },
        mounted() {
            Utilities.log('FormLayout MOUNTED', this.single);
            this.TimeLoop();
        },
        watch:
        {
            single: function (n, o) {
                Utilities.log("Formlayout single watch new:", n, " old:", o)
            },
            validateError: function (n, o) {
                try {
                    for (var err in n) {
                        if (n[err].indexOf('Hata :') > -1) {
                            this.diaIcon = 'warning';
                            this.diaTitle = this.$root.$i18n.t('DM_Warning');
                            break;
                        }
                        else if (n[err] != 'İşlem Başarıyla Tamamlandı' && n[err] != 'Transaction Completed Successfully') {
                            this.diaIcon = 'error';
                            this.diaTitle = this.$root.$i18n.t('DM_Error');
                            break;
                        }
                        else {
                            this.diaIcon = 'success';
                            this.diaTitle = this.$root.$i18n.t('DM_Success');
                            break;
                        }
                    }
                }
                catch (e) {

                }
            }
        }
    };
</script>
